import { useState } from 'react';

import useWindowSize from 'hooks/use-windowSize';

import CourseTabView from './CourseTabView/CourseTabView';
import CartCarousel from '@core/components/cartcarousel/CartCarousel';
import CourseCard from 'components/common/coursecard/CourseCard';
import JobQualificationCard from 'components/common/jobqualificationcard/JobQualificationCard';
import LinkButton from '@core/components/linkbutton/LinkButton';

import QualifBGDesktop from 'assets/images/qualification-bg-desktop.svg?url';
import QualifBGMobile from 'assets/images/qualification-bg-mobile.svg?url';

import classes from './Course.module.scss';

const Course = ({ section, origin }) => {
	if (section.results.length === 0) return;
	const { mobileDevice, tabletDevice } = useWindowSize();

	const [viewAllLink] = useState(() => {
		if (section.custom) {
			return `/show-more-products/${origin}/${section.show_more_id}?ordering=${
				section.ordering ?? ''
			}&content=${section.type}${section.filters ? `&filters=${section.filters}` : ''}`;
		} else {
			let link = '/explore';
			let ordering = '';
			let filters = '';
			if (section.ordering) {
				ordering = `ordering=${section.ordering}`;
			}
			if (section.filters && Object.keys(section.filters).length > 0) {
				filters = Object.keys(section.filters)
					.map((key) => `${key}=${section.filters[key]}`)
					.join('&');
			}
			if (!!ordering || !!filters) {
				link = link + '?' + [ordering, filters].join('&');
			}
			return link;
		}
	});

	switch (section.view_type) {
		case 'content-big-card':
			return (
				<div
					className={`${classes['content-big-card']}`}
					style={{
						backgroundImage: `url(${
							mobileDevice || tabletDevice ? QualifBGMobile : QualifBGDesktop
						})`
					}}>
					<div className="container d-flex flex-column align-items-center gap-4">
						<div
							className={`${classes['content-big-card-headline']} d-flex flex-column gap-2 w-100`}>
							<h3>{section.headline}</h3>
							<p>{section.description}</p>
						</div>
						<div
							className={`${classes['content-big-card__header']} d-flex flex-row justify-content-between align-items-center w-100`}>
							<header>
								<h2>{section.title}</h2>
							</header>
							<div className="d-flex flex-row align-items-center justify-content-center gap-3">
								<div className="d-none d-xl-flex flex-row align-items-center gap-3"></div>
								{section.does_show_more && (
									<LinkButton
										className={'d-none d-md-block'}
										kind="simple"
										to={`/show-more-products/${origin}/${section.show_more_id}?ordering=${
											section.ordering ?? ''
										}&content=${section.type}`}>
										View All
									</LinkButton>
								)}
							</div>
						</div>
						<article
							className={`${classes['content-big-card__contents']} d-flex flex-column flex-xl-row w-100 gap-4 gap-xl-4 align-items-baseline`}>
							{section.results.map((content) => (
								<div key={content.id} className={`d-flex align-items-center col-12 col-xl-6`}>
									<JobQualificationCard content={content} />
								</div>
							))}
						</article>
						{section.does_show_more && (
							<LinkButton className={'d-block d-md-none'} kind="simple" to={viewAllLink}>
								View All
							</LinkButton>
						)}
					</div>
				</div>
			);
		case 'course-tab-view':
			return <CourseTabView section={section} viewAllLink={viewAllLink} />;
		default:
			return (
				<CartCarousel section={section} origin={origin} viewAllLink={viewAllLink}>
					{section.results.map((content) => (
						<div
							key={content.id}
							className={`${classes['cart-wrapper']} d-flex align-items-center`}>
							<CourseCard course={content} className="home-page" />
						</div>
					))}
				</CartCarousel>
			);
	}
};

export default Course;
