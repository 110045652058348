import React, { useRef } from 'react';

import ActionButton from '../actionbutton/ActionButton';
import LinkButton from '../linkbutton/LinkButton';

import ArrowRightIcon from 'assets/icons/arrow-right-gray.svg';
import ArrowLeftIcon from 'assets/icons/arrow-left-gray.svg';

import classes from './CartCarousel.module.scss';

const CartCarousel = ({ children, section, origin, viewAllLink }) => {
	const containerRef = useRef();

	const scroll = (scrollOffset) => {
		containerRef.current.scrollLeft += scrollOffset;
	};

	return (
		<section
			className={`${classes['cart-carousel']} d-flex flex-column ${
				origin === 'my-uni' ? '' : 'container'
			}`}>
			<div className={`${classes['cart-carousl-headline']} d-flex flex-column gap-2`}>
				<h3>{section.headline}</h3>
				<p>{section.description}</p>
			</div>
			<div
				className={`${classes['cart-carousel__header']} d-flex flex-row justify-content-between align-items-center`}>
				<header>
					<h2>{section.title}</h2>
				</header>
				<div className="d-flex flex-row align-items-center justify-content-center gap-3">
					<div className="d-none d-xl-flex flex-row align-items-center gap-3">
						{containerRef.current?.scrollWidth > containerRef.current?.clientWidth && (
							<>
								<ActionButton kind="blank" onClick={() => scroll(-500)}>
									<ArrowLeftIcon />
								</ActionButton>
								<ActionButton kind="blank" onClick={() => scroll(500)}>
									<ArrowRightIcon />
								</ActionButton>
							</>
						)}
					</div>
					{section.does_show_more && (
						<LinkButton className={'d-none d-md-block'} kind="simple" to={viewAllLink}>
							View All
						</LinkButton>
					)}
				</div>
			</div>
			<article
				ref={containerRef}
				className={`${classes['cart-carousel__contents']} d-flex flex-row `}>
				{children}
			</article>
			{section.does_show_more && (
				<LinkButton
					className={'d-block d-md-none'}
					kind="simple"
					to={`/show-more-products/${origin}/${section.show_more_id}?ordering=${
						section.ordering ?? ''
					}&content=${section.type}`}>
					View All
				</LinkButton>
			)}
		</section>
	);
};

export default CartCarousel;
