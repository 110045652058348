import LinkButton from '@core/components/linkbutton/LinkButton';

import LearnAndGrowImg from 'assets/images/learn-and-grow.png';

import classes from './LearnAndGrow.module.scss';

const LearnAndGrow = () => {
	return (
		<div className={`${classes['learn-and-grow-wrapper']} py-5 `}>
			<div className="container mt-3">
				<div className={`${classes['learn-and-grow-container']} py-5 row gap-4 gap-lg-0`}>
					<div
						className={`${classes['learn-and-grow-data']} col-12 col-lg-6 d-flex flex-column gap-3 order-2 order-lg-1`}>
						<div>
							<h3>Learn and Grow</h3>
							<p>
								Embark on a transformative learning journey with Pharmuni, where your growth and
								career advancement in the Pharma industry take center stage
							</p>
						</div>
						<ul className="d-flex flex-column justify-content-between gap-2">
							<li>
								<h4>Learn</h4>
								<p>
									Delve into a curated selection of courses designed to enhance your expertise and
									position you as a specialist in the pharmaceutical field
								</p>
							</li>
							<li>
								<h4>Job Board</h4>
								<p>
									Connect with leading Pharma employers and discover a world of opportunities
									through our exclusive job board tailored for industry professionals.
								</p>
							</li>
							<li>
								<h4>Coaching</h4>
								<p>
									Enhance your professional journey with personalized coaching from C-Level Pharma
									experts, and navigate your career path with confidence.
								</p>
							</li>
						</ul>
					</div>
					<img
						className="col-12 col-lg-6 order-1 order-lg-2"
						src={LearnAndGrowImg.src}
						alt="learner-business-university"
					/>
				</div>
				<div className="d-flex justify-content-center">
					<LinkButton
						kind="secondary"
						to="/our-impact/"
						style={{ width: 'max-content', padding: '16px 24px' }}>
						Explore Our Impact
					</LinkButton>
				</div>
			</div>
		</div>
	);
};

export default LearnAndGrow;
