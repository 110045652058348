import { useState } from 'react';

import IconButton from '@mui/material/IconButton';

import MenuItem from '@mui/material/MenuItem';
import MenuMUI from '@mui/material/Menu';

import VerticalDotsIcon from 'assets/icons/vertical-dots-icon.svg';

import classes from './Menu.module.scss';

const Menu = ({ menus, icon }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = (callback) => {
		setAnchorEl(null);
		typeof callback === 'function' && callback();
	};

	const ITEM_HEIGHT = 32;

	return (
		<>
			<IconButton
				id="long-button"
				aria-controls={open ? 'long-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup="true"
				onClick={handleClick}>
				{icon || <VerticalDotsIcon />}
			</IconButton>
			<MenuMUI
				id="long-menu"
				MenuListProps={{
					'aria-labelledby': 'long-button'
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 4.5,
						width: '20ch'
					}
				}}>
				{menus.map((menu) => (
					<MenuItem
						key={menu.label}
						sx={{ padding: '10px' }}
						onClick={() => handleClose(menu.callback)}>
						<div className={classes['menu-item']}>
							<div className={classes['menu-item-icon']}>{menu.icon}</div>
							<p className={classes['menu-item-text']}>{menu.label}</p>
						</div>
					</MenuItem>
				))}
			</MenuMUI>
		</>
	);
};

export default Menu;
