import { useMutation } from 'react-query';

import CartCarousel from '@core/components/cartcarousel/CartCarousel';
import Menu from '@core/components/dropDownMenu/Menu';

import { ContentCertificatePdfService } from 'services/core';

import DownloadIcon from 'assets/icons/download-icon.svg';
import LinkIcon from 'assets/icons/link-icon.svg';
import VerticalDotsIcon from 'assets/icons/vertical-dots-icon.svg';


import classes from './Certificate.module.scss';

const Certificate = ({ section, origin }) => {
	const { mutate } = useMutation(ContentCertificatePdfService);
	const getLink = (id) => {
		mutate(id, {
			onSuccess: (data) => {
				location.href = data.data.certificate;
			}
		});
	};
	const copyToCLipboard = async (thubmnail_id) => {
		await navigator.clipboard.writeText(thubmnail_id);
	};
	return (
		<>
			{section.results.length !== 0 && (
				<CartCarousel section={section} origin={origin}>
					<section className={`${classes['certificate-container']} pb-5`}>
						{section.results.map((item) => (
							<div key={item.certificate_id} className={classes['certificate-card']}>
								<div className={classes['image']}>
									<img alt="" src={item.certificate_thumbnail} />
								</div>
								<div className={classes['info']}>
									<aside className={classes['left-side']}>
										<h1 className={classes['header']}>{item.title}</h1>
										<p className={classes['sub-header']}>
											Generated on {item.certificate_generated_at}
										</p>
									</aside>
									<aside className={classes['right-side']}>
										<Menu
											icon={<VerticalDotsIcon />}
											menus={[
												{
													label: 'Download',
													link: false,
													icon: <DownloadIcon />,
													callback: () => getLink(item.certificate_id)
												},
												{
													label: 'Copy Link',
													link: false,
													icon: <LinkIcon />,
													callback: () => copyToCLipboard(item.certificate_thumbnail)
												}
											]}></Menu>
									</aside>
								</div>
							</div>
						))}
					</section>
				</CartCarousel>
			)}
		</>
	);
};

export default Certificate;
