import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Autoplay, Pagination } from 'swiper';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.scss';

import classes from './CarouselWrapper.module.scss';

SwiperCore.use([Autoplay, Pagination]);

const CarouselWrapper = ({ slides = [], delay = 6000 }) => {
	return (
		<Swiper
			spaceBetween={30}
			centeredSlides={true}
			autoplay={{
				delay,
				disableOnInteraction: false
			}}
			className={classes['carousel-wrapper']}>
			{slides.map((item, index) => (
				<SwiperSlide key={index}>{item}</SwiperSlide>
			))}
		</Swiper>
	);
};

export default CarouselWrapper;
