import { sendRequest } from './index';
import { API_NEWSLETTER_URL } from '@core/constants/API';

export const RequestNewsletter = (payload) => {
	return sendRequest({
		url: `${API_NEWSLETTER_URL}`,
		method: 'POST',
		body: payload
	});
};

export const ConfirmNewsletter = (payload) => {
	return sendRequest({
		url: `${API_NEWSLETTER_URL}confirm/`,
		method: 'POST',
		body: payload
	});
};

export const UnsubscribeNewsletter = (payload) => {
	return sendRequest({
		url: `${API_NEWSLETTER_URL}unsubscribe`,
		method: 'POST',
		body: payload
	});
};
