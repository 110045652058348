import { useMutation } from 'react-query';
import ScrollAnimation from 'react-animate-on-scroll';

import UseToast from 'hooks/use-toast';
import UseModal from 'hooks/use-modal';

import ActionButton from '@core/components/actionbutton/ActionButton';
import Form from './Form';
import Modal from '@core/components/Modal/Modal';

import { isUserLoggedIn } from 'utils/authentication';
import { RequestNewsletter } from 'services/newsletter';

import { MOBILE_SLIDE_UP } from '@core/constants/ModalTypes';

import NewsletterIcon1 from 'assets/icons/newsletter-icon-1.svg';
import NewsletterIcon2 from 'assets/icons/newsletter-icon-2.svg';
import NewsletterIcon3 from 'assets/icons/newsletter-icon-3.svg';

import classes from './Newsletter.module.scss';
import LinkButton from '@core/components/linkbutton/LinkButton';

const Newsletter = ({ section }) => {
	const { toast, ToastContainer } = UseToast();
	const { mutate } = useMutation(['newsletter-request'], RequestNewsletter, {
		onSuccess: () => {
			toast(
				'We’ve sent a verification Email. Please follow the instruction in the email',
				'success'
			);
		}
	});
	const { show, showModal, destroyModal } = UseModal();

	const subscribeHandler = () => {
		if (isUserLoggedIn()) {
			mutate({});
		} else {
			showModal();
		}
	};

	return (
		<>
			<ToastContainer />
			<section id="subscription" className={`${classes['newsletter-wrapper']}`}>
				<div className="container d-flex flex-column d-flex-md-row">
					<h2>{section.title}</h2>
					<div className={`${classes['newsletter-wrapper-cards']} d-flex flex-column flex-xl-row`}>
						<ScrollAnimation animateIn="bounceIn">
							<div>
								<NewsletterIcon1 />
								<h4>Ignite Your Passion</h4>
							</div>
							<p>
								Our Pharmuni newsletter delivers inspiring success stories and industry
								breakthroughs, motivating you to excel in your career.
							</p>
						</ScrollAnimation>
						<ScrollAnimation animateIn="bounceIn">
							<div>
								<NewsletterIcon2 />
								<h4>Unlock Exclusive Opportunities</h4>
							</div>
							<p>
								Subscribers enjoy early access to courses, insights, and networking events, boosting
								your competitive edge in the industry.
							</p>
						</ScrollAnimation>
						<ScrollAnimation animateIn="bounceIn">
							<div>
								<NewsletterIcon3 />
								<h4>Be Part of a Supportive Community</h4>
							</div>
							<p>
								As a Pharmuni newsletter member, you'll become part of a vibrant, like-minded
								community.
							</p>
						</ScrollAnimation>
					</div>
					{/* <ActionButton onClick={subscribeHandler}>Subscribe to newsletter</ActionButton> */}
					<LinkButton
						kind='primary-color'
						href={'https://www.linkedin.com/newsletters/pharmuni-7130499287200718851/'}
						target="_blank" rel="noopener noreferrer"
					>
						Subscribe to newsletter
					</LinkButton>
				</div>
			</section>
			<Modal
				show={show}
				title="Join Our Community"
				closeHandler={destroyModal}
				type={MOBILE_SLIDE_UP}>
				<Form />
			</Modal>
		</>
	);
};

export default Newsletter;
