import Link from 'next/link';

import PlayIcon from 'assets/icons/play-icon.svg';

import UseSlugRoute from 'hooks/use-slugroute';

import classes from './VerticalCard.module.scss';

const VerticalCard = ({ card }) => {
	const slugRoute = UseSlugRoute();
	const content_type = card.type === 'course' ? 'job-qualifications' : 'courses';
	return (
		<Link
			href={slugRoute(`/${content_type}/${card.id}`, card.slug)}
			className={classes['card-container']}>
			<img src={card.image} alt={card.title} />
			<div className={`${classes['card-info']}`}>
				<p>{card.title}</p>
				<div className={classes['play-button']}>
					<PlayIcon />
				</div>
			</div>
		</Link>
	);
};

export default VerticalCard;
