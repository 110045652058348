import React from 'react';

import CountUp from 'react-countup';

import classes from './DataCounter.module.scss';

const DataCounter = ({data}) => {
    return (
        <div className='container my-5'>
            <div className={classes['wrapper']}>
                <div className="row d-flex justify-content-center mb-5">
                    <div className="col-md-3 col-12 d-flex flex-column align-items-center justify-content-center">
                        <CountUp
                            start={0}
                            end={data?.pharmuni_learners}
                            duration={2.5}
                            separator=","
                            prefix="+"
                            enableScrollSpy={true}
                        >
                            {({ countUpRef }) => (
                                <div className={classes['counter']}>
                                    <span ref={countUpRef} />
                                </div>
                            )}
                        </CountUp>
                        <span className={classes['description']}>
                            Pharmuni Learners
                        </span>
                    </div>
                    <div className="col-md-3 col-12 d-flex flex-column align-items-center justify-content-center">
                        <CountUp
                            start={0}
                            end={data?.countries}
                            duration={2.5}
                            separator=","
                            prefix="+"
                            enableScrollSpy={true}
                        >
                            {({ countUpRef }) => (
                                <div className={classes['counter']}>
                                    <span ref={countUpRef} />
                                </div>
                            )}
                        </CountUp>
                        <span className={classes['description']}>
                            Countries
                        </span>
                    </div>
                    <div className="col-md-3 col-12 d-flex flex-column align-items-center justify-content-center">
                        <CountUp
                            start={0}
                            end={data?.completed_courses}
                            duration={2.5}
                            separator=","
                            prefix="+"
                            enableScrollSpy={true}
                        >
                            {({ countUpRef }) => (
                                <div className={classes['counter']}>
                                    <span ref={countUpRef} />
                                </div>
                            )}
                        </CountUp>
                        <span className={classes['description']}>
                            Completed courses
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DataCounter;