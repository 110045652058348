import React from 'react';

import Rating from '@mui/material/Rating';

import ProfileImage from '@core/assets/images/profile-image.png';

import classes from './TestimonialCard.module.scss';

const TestimonialCard = ({ data }) => {
    return (
        <>
            {data && (
                <div className={classes['card']}>
                    <div className={classes['header']}>
                        <div className='d-flex align-items-center'>
                            <img src={data?.user_image ? data.user_image : ProfileImage.src} alt='profile' />
                            <span className='ms-2'>{data?.name} </span>
                        </div>
                        <img src={data?.platform_logo} alt='platform' />
                    </div>
                    <div>
                        <Rating
                            name="read-only"
                            value={data?.rate}
                            readOnly
                            emptyIcon={
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 20" fill="none">
                                    <path d="M9.06845 1.38645C9.39966 0.537949 10.6003 0.537949 10.9315 1.38645L12.8138 6.20855C12.956 6.57265 13.2972 6.82058 13.6874 6.84324L18.8552 7.1433C19.7645 7.1961 20.1355 8.338 19.4309 8.9152L15.4265 12.1955C15.1241 12.4432 14.9938 12.8443 15.0928 13.2224L16.4043 18.23C16.6351 19.1111 15.6638 19.8168 14.8971 19.3251L10.5399 16.5303C10.2109 16.3193 9.7891 16.3193 9.4601 16.5303L5.10293 19.3251C4.33624 19.8168 3.36488 19.1111 3.59566 18.23L4.90721 13.2224C5.00624 12.8443 4.8759 12.4432 4.57353 12.1955L0.569105 8.9152C-0.135515 8.338 0.235512 7.1961 1.14483 7.1433L6.31258 6.84324C6.70279 6.82058 7.04403 6.57265 7.18616 6.20854L9.06845 1.38645Z" fill="#D9D9D9" />
                                </svg>
                            }
                        />
                    </div>
                    <div className={classes['date']}>
                        {data?.date}
                    </div>
                    <p>
                        {data?.comment}
                    </p>
                </div>
            )}
        </>
    );
};

export default TestimonialCard;