import React from 'react';

import { useRef, useCallback, useState } from 'react';

import ActionButton from '@core/components/actionbutton/ActionButton';
import LinkButton from '@core/components/linkbutton/LinkButton';

import ArrowRightIcon from 'assets/icons/arrow-right-gray.svg';
import ArrowLeftIcon from 'assets/icons/arrow-left-gray.svg';

import { Swiper } from 'swiper/react';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.scss';

import useWindowSize from 'hooks/use-windowSize';

import classes from './CartCarouselSwiperWithCategory.module.scss';

const CartCarouselSwiperWithCategory = ({ children, section }) => {
    const { desktopDevice, mobileDevice, landscapeTabletDevice, landscapeMobileDevice } = useWindowSize();

    const sliderRef = useRef(null);
    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);
    return (
        <>
            <section
                className={`${classes['wrapper']} `}>

                <div
                    className={`${classes['cart-carousel__header']} d-flex flex-row justify-content-between align-items-center mb-4`}>
                    <header>
                        <h2>{section.title}</h2>
                    </header>
                    <div className="d-flex flex-row align-items-center justify-content-center gap-3">
                        <div className="d-none d-xl-flex flex-row align-items-center gap-0">
                            <>
                                <ActionButton kind="blank" onClick={handlePrev}>
                                    <ArrowLeftIcon />
                                </ActionButton>
                                <ActionButton kind="blank" onClick={handleNext}>
                                    <ArrowRightIcon />
                                </ActionButton>
                            </>
                        </div>
                        {section.does_show_more && (
                            <LinkButton className={'d-none d-md-block'} kind="simple" to={'/explore'}>
                                View All
                            </LinkButton>
                        )}
                    </div>
                </div>

                {section.does_show_more && (
                    <div className={classes['mobile-view-all']}>
                        <LinkButton
                            className={'d-block d-md-none'}
                            kind="simple"
                            // to={`/show-more-products/${origin}/${section.show_more_id}?ordering=${
                            // 	section.ordering ?? ''
                            // }&content=${section.type}`}
                            to={'/explore'}
                        >
                            View All
                        </LinkButton>
                    </div>
                )}
            </section>
            <div className={classes['slider-wrapper']}>
                <Swiper
                    ref={sliderRef}
                    slidesPerView={desktopDevice ? 1.8 : mobileDevice ? landscapeMobileDevice ? 1.5 : 1.2 : landscapeTabletDevice ? 2.8 : 1.8}
                spaceBetween={30}
                    className="mySwiper">
                {children}
            </Swiper>
        </div >
        </>
    );
};

export default CartCarouselSwiperWithCategory;