import classes from './ComingSoon.module.scss';

const ComingSoon = ({ section }) => {
	return (
		<>
			{section?.results && section?.results?.length > 0 && (
				<div className={`container ${classes['coming-soon-wrapper']} d-flex flex-column py-5`}>
					<h2>{section.title}</h2>
					<div className={`d-flex flex-column align-items-center justify-content-center`}>
						<div className={`${classes['coming-soon-card']} d-flex flex-column align-items-center`}>
							<img src={section.results[0].image} alt={section.results[0].title} />
							<div className={` d-flex flex-column`}>
								<h5>Coming Feature:</h5>
								<h3>{section.results[0].headline}</h3>
								<h2>{section.results[0].title}</h2>
								<p>{section.results[0].description}</p>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
export default ComingSoon;
