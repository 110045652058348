import React, { useState } from 'react';

import classes from './TruncateText.module.scss';

import ActionButton from '@core/components/actionbutton/ActionButton';

import ArrowRight from 'assets/icons/arrow-right.svg';

const TruncateText = ({ text, readmore = undefined, lines, className }) => {
	const [showMore, setShowMore] = useState();
	return (
		<>
			<p
				className={`${classes['truncate']} ${className || ''}`}
				style={{
					overflow: showMore ? 'visible' : 'hidden',
					WebkitLineClamp: showMore ? 'unset' : lines,
					lineClamp: showMore ? 'unset' : lines
				}}>
				{text}
			</p>
			{readmore && !showMore && (
				<ActionButton
					className={`${classes['truncate-readmore']}`}
					kind={'blank'}
					onClick={() => setShowMore(true)}>
					Read More
					<ArrowRight />
				</ActionButton>
			)}
			{readmore && showMore && (
				<ActionButton
					className={`${classes['truncate-readmore']}`}
					kind={'blank'}
					onClick={() => setShowMore(false)}>
					Less
					<ArrowRight />
				</ActionButton>
			)}
		</>
	);
};

export default TruncateText;
