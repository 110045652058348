import LinkButton from '@core/components/linkbutton/LinkButton';
import ContentPrice from '../contentprice/ContentPrice';

import StarIcon from 'assets/icons/star-rating.svg';
import ArrowRightIcon from 'assets/icons/arrow-right.svg';
import VIPIcon from 'assets/icons/vip-card-icon.svg';

import classes from './ContentRecommendationCard.module.scss';

const ContentRecommendationCard = ({ content, hasPrice = true }) => {
	return (
		
			<div className={`${classes['content-recommendation-card']} d-flex flex-column gap-4`}>

				<div className={`${classes['content-recommendation-card__header']}`}>
					<LinkButton
						kind="simple"
						to={`/${content.type === 'lecture' ? 'courses' : 'job-qualifications'}/${content.id}/${content.slug
							}`}>
						<img src={content.image} alt={content.title} />
					</LinkButton>
					{content.status === 'coming_soon' ? (
						<span className={`${classes['content-recommendation-card__header__coming-soon']}`}>
							Coming Soon
						</span>
					) : (
						<div
							className={`${classes['content-recommendation-card__header__normal']} d-flex flex-column gap-2`}>
							{content.has_been_updated && <span>Updated</span>}
							<div className="d-flex flex-row gap-1 align-items-center justify-content-center">
								<StarIcon /> <h6>{content.rate_average}</h6>{' '}
								<span className={`${classes['seperator-dot']}`} />{' '}
								<p>{content.participants_count} participants</p>
							</div>
						</div>
					)}
					{/* {content.has_vip_access && <div className={`${classes['vip-access']}`}>
						<VIPIcon />
					</div>} */}
				</div>
				<div className={`${classes['content-recommendation-card__desc']} d-flex flex-column gap-3`}>
					<h5>{content.title}</h5>
					<p>{content.short_introduction}</p>
					<div className="d-flex flex-row justify-content-between">
						<LinkButton
							kind="simple"
							to={`/${content.type === 'lecture' ? 'courses' : 'job-qualifications'}/${content.id}/${content.slug
								}`}>
							Details <ArrowRightIcon />
						</LinkButton>
						{hasPrice && <ContentPrice {...content} />}
					</div>
				</div>

			</div>
	);
};

export default ContentRecommendationCard;
