import ContentSmallCard from 'components/common/ContentSmallCard/ContentSmallCard';
import LinkButton from '@core/components/linkbutton/LinkButton';

import useWindowSize from 'hooks/use-windowSize';

import classes from './CourseTabViewMobile.module.scss';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.scss';

const CourseTabViewMobile = ({ data, viewAllLink }) => {
  const { mobileDevice } = useWindowSize();
	return (
		<div className={`${classes['jq-tab-view-mobile-wrapper']} d-flex flex-column gap-3 py-5`}>
			<h3>{data.headline}</h3>
			<p>{data.description}</p>
			<div>
				<Swiper
					slidesPerView={mobileDevice ? 1.1 : 2.1}
					spaceBetween={30}
					// pagination={{
					// 	clickable: true
					// }}
					// modules={[Pagination]}
					className="mySwiper">
					{data?.results?.map((card) => (
						<SwiperSlide style={{ height: 'auto' }} key={card.id + card.type}>
							<ContentSmallCard data={card} />
						</SwiperSlide>
					))}
				</Swiper>
			</div>
			<LinkButton className={'mt-3'} kind='simple' to={viewAllLink}>
				Explore all job qualifications
			</LinkButton>
		</div>
	);
};

export default CourseTabViewMobile;
