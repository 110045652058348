import Link from 'next/link';

import TruncateText from '../truncatetext/TruncateText';

import StarIcon from 'assets/icons/star-rating.svg';

import classes from './JobQualificationCard.module.scss';

const JobQualificationCard = ({ content }) => {
	return (
		<Link
			href={`job-qualifications/${content.id}/${content.slug}`}
			style={{ color: 'unset', textDecoration: 'none', width: '100%' }}>
			<div className={`${classes['job-qualification-card-wrapper']} d-flex flex-column gap-3`}>
				<img src={content.image} alt={content.title} />
				<div className={classes['job-qualification-card-wrapper__details']}>
					<h4>{content.title}</h4>
					<div className="p-0 p-md-2">
						<div
							className={`${classes['job-qualification-card-wrapper__details__categories']} d-flex flex-wrap flex-row gap-2 mb-3`}>
							{/* {content.categories &&
								content?.categories?.length > 0 &&
								content.categories.map((ctgr) => {
									return (
										<div
											key={ctgr.name}
											className={`${classes['categorie']} d-flex flex-row align-items-center`}>
											{ctgr.image && <img src={ctgr.image} alt={ctgr.name} />}
											<p className='m-0'>{ctgr.name}</p>
										</div>
									);
								})} */}
						</div>
						<div
							className={`d-flex flex-row ${classes['job-qualification-card-wrapper__details__desciription']}`}>
							<div>
								<span />
							</div>
							<div>
								<TruncateText text={content.description} lines={4} />
							</div>
						</div>
						<div
							className={`${classes['job-qualification-card-wrapper__details__rating']} d-flex flex-row align-items-center`}>
							<StarIcon /> <span>{content.rate_average}</span>{' '}
							<span className={classes['dot']}></span>{' '}
							<p>{content.participants_count} participants</p>
						</div>
					</div>
				</div>
			</div>
		</Link>
	);
};

export default JobQualificationCard;
