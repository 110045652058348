import CarouselWrapper from '@core/components/carouselwrapper/CarouselWrapper';

import FeedbackImage from 'assets/images/feedback-image.png';
import StarIcon from 'assets/icons/star-rating.svg';

import classes from './Feedback.module.scss';

const Feedback = ({ section }) => {
	return (
		<>
			{section.results && section.results.length > 0 && (
				<div className={`container ${classes['top-feedback-wrapper']}`}>
					<h2>
						Hear from Our Thriving Community in <span>Pharmuni</span>
					</h2>
					<div
						className={`d-flex flex-column flex-md-row gap-2 gap-md-0 align-items-center justify-content-center ${classes['feedback-comment-wrapper']}`}>
						<div className={`${classes['feedback-image']} col-12 col-md-5 col-xl-6`}>
							<img src={FeedbackImage.src} alt="feedback-img" />
						</div>
						<div className="col-12 col-md-7 col-xl-6 ">
							<CarouselWrapper
								slides={
									section.results &&
									section.results.map((comment) => {
										return (
											<div
												key={comment.name}
												className={`${classes['feedback-comment']} d-flex flex-column justify-content-center gap-2 gap-md-4`}>
												<p>“ {comment.comment} ”</p>
												<div className="d-flex flex-row align-items-center">
													<p>{comment.name}</p>
													<span />
													<StarIcon /> {comment.rate}
												</div>
											</div>
										);
									})
								}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Feedback;
