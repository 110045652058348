import LinkButton from '@core/components/linkbutton/LinkButton';
import ContentCategoryChip from '../contentcategorychip/ContentCategoryChip';

import ArrowRightIcon from 'assets/icons/arrow-right.svg';

import classes from './ContentSmallCard.module.scss';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.scss';

const ContentSmallCard = ({ data }) => {
	return (
		<div className={`${classes['content-small-card-wrapper']} d-flex flex-column gap-3`}>
			<img src={data.image} alt={data.title} />
			<h3>{data.title}</h3>

			<div className="d-flex flex-row flex-wrap gap-1">
				<Swiper
					slidesPerView={2.1}
					spaceBetween={30}
					className="mySwiper">
					{data?.results?.map((card) => (
						<SwiperSlide style={{ height: 'auto' }} key={card.id + card.type}>
							{/* {data.categories.map((category) => {
								return <ContentCategoryChip key={category.id || category.name} {...category} />;
							})} */}
						</SwiperSlide>
					))}
				</Swiper>
			</div>
			<div
				className={`${classes['content-small-card-footer']} d-flex flex justify-content-between`}>
				<span>{data.lecture_count} Courses included</span>
				<LinkButton kind="simple" to={`/job-qualifications/${data.id}/${data.slug}`}>
					Details <ArrowRightIcon />
				</LinkButton>
			</div>
		</div>
	);
};

export default ContentSmallCard;
