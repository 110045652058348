import classes from './ContentCategoryChip.module.scss';

const ContentCategoryChip = ({ color, image, name }) => {
	return (
		<div className={`d-flex flex-row gap-1 ${classes['content-category-chip']}`}>
			<img src={image} alt={name} />
			<span>{name}</span>
		</div>
	);
};

export default ContentCategoryChip;
