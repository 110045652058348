import React from 'react';

import ScrollAnimation from 'react-animate-on-scroll';

import LinkButton from '@core/components/linkbutton/LinkButton';

import classes from './Hero.module.scss';


const Hero = () => {
    return (
        <div className='container'>
            <div className={`${classes['hero-container']} d-flex flex-column justify-content-center align-items-center`}>
                <h1>
                    Finding the perfect job is easier with
                    {/* <span className={classes["sign"]}>
                        <span className={classes["fast-flicker"]}>P</span><span className={classes["slow-flicker"]}>har</span><span className={classes["flicker"]}>m</span><span className={classes["meduim-flicker"]}>uni</span>
                    </span> */}
                    {/* <span title="Pharmuni" className={classes['anim']}>Pharmuni</span> */}
                    {/* <span className={classes["wrapper"]}>
                        PHARMUNI
                    </span> */}
                    <div className={classes['pharmuni-name']}>
                        <ScrollAnimation animateIn="fadeIn" duration={0.2} >
                            P
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeIn" duration={0.2} delay={150}>
                            h
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeIn" duration={0.2} delay={300}>
                            a
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeIn" duration={0.2} delay={450}>
                            r
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeIn" duration={0.2} delay={600}>
                            m
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeIn" duration={0.2} delay={750}>
                            u
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeIn" duration={0.2} delay={900}>
                            n
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeIn" duration={0.2} delay={1050}>
                            i
                        </ScrollAnimation>
                    </div>
                </h1>

                <p>
                    We're creating the resources to help you take the next step in your career in the Pharmaceutical and Biotech Industry. Acquire relevant skills, watch your growth, optimize your resume and stand out to potential employers.
                </p>
                <LinkButton
                    kind='primary-color'
                    to={'/get-started'}
                >
                    Join for Free
                </LinkButton>
            </div>
            <script async src={'animation.js'} onLoad={() => console.log('script loaded')}></script>
        </div>
    );
};

export default Hero;