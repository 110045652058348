import CourseTabViewDesktop from './CourseTabViewDesktop';
import CourseTabViewMobile from './CourseTabViewMobile';

import useWindowSize from 'hooks/use-windowSize';

import classes from './CourseTabView.module.scss';

const CourseTabView = ({ section, viewAllLink }) => {
	const { desktopDevice } = useWindowSize();
	return (
		<div className={`${classes['course-tab-view-wrapper']} py-5`}>
			<div className="container">
				{desktopDevice ? (
					<CourseTabViewDesktop data={section} viewAllLink={viewAllLink} />
				) : (
					<CourseTabViewMobile data={section} viewAllLink={viewAllLink} />
				)}
			</div>
		</div>
	);
};

export default CourseTabView;
