import React from 'react';

import PharmUniLogo from '@core/assets/logo/pharmuni-logo-24x24.svg';
import InstagramLike from 'assets/icons/instagram-like.svg';
import InstagramComment from 'assets/icons/instagram-comment.svg';
import InstagramSend from 'assets/icons/instagram-send.svg';
import InstagramBookmark from 'assets/icons/instagram-bookmark.svg';

import classes from './Instagram.module.scss';

const Instagram = ({ section }) => {
	if (section.results?.length === 0) {
		return;
	}
	return (
		<section className={`${classes['instagram-wrapper']} d-flex flex-wrap flex-column container`}>
			<h2>{section.title}</h2>
			<ul>
				{section.results?.map((insta_post, index) => (
					<li key={index}>
						<a
							className="d-flex flex-wrap flex-column gap-3"
							href={insta_post.link}
							target="_blank"
							rel="noreferrer">
							<header className="d-flex flex-row">
								<PharmUniLogo /> <h6>Pharmuni_com</h6>
							</header>
							<img src={insta_post.image} alt={`instagram-post-${index}`} />
							<div className="d-flex flex-row justify-content-between">
								<div className="d-flex flex-row">
									<InstagramLike />
									<InstagramComment />
									<InstagramSend />
								</div>
								<InstagramBookmark />
							</div>
						</a>
					</li>
				))}
			</ul>
		</section>
	);
};

export default Instagram;
