import { useState, useRef, useCallback } from 'react';

import ContentRecommendationCard from 'components/common/contentrecommendationcard/ContentRecommendationCard';
import ActionButton from '@core/components/actionbutton/ActionButton';
import LinkButton from '@core/components/linkbutton/LinkButton';
import ContentCategoryChip from 'components/common/contentcategorychip/ContentCategoryChip';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import ArrowRightIcon from 'assets/icons/arrow-right-gray.svg';
import ArrowRightIconPink from 'assets/icons/arrow-right.svg';
import ArrowLeftIcon from 'assets/icons/arrow-left-gray.svg';

import classes from './CourseTabViewDesktop.module.scss';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.scss';

const CourseTabViewDesktop = ({ data, viewAllLink }) => {
	const [value, setValue] = useState(0);

	const handleChange = (e, newValue) => {
		setValue(newValue);
	};

	const sliderRef = useRef(null);

	const handlePrev = useCallback(() => {
		if (!sliderRef.current) return;
		sliderRef.current.swiper.slidePrev();
	}, []);

	const handleNext = useCallback(() => {
		if (!sliderRef.current) return;
		sliderRef.current.swiper.slideNext();
	}, []);

	function TabPanel(props) {
		const { children, value, index, JQ, ...other } = props;

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`vertical-tabpanel-${index}`}
				aria-labelledby={`vertical-tab-${index}`}
				className={`${classes['jq-tab-content-wrapper']}`}
				style={{ flexGrow: '1' }}
				{...other}>
				{value === index && (
					<div className="d-flex flex-column gap-4">
						<div
							className={`${classes['jq-tab-content-title']} d-flex flex-row justify-content-between`}>
							<div className="d-flex gap-1">
								{/* {JQ.categories.map((cat) => {
									return <ContentCategoryChip key={cat.id || cat.name} {...cat} />;
								})} */}
							</div>
							<div className="d-flex flex-row gap-2">
								<div>
									<ActionButton kind="blank" onClick={handlePrev}>
										<ArrowLeftIcon />
									</ActionButton>
									<ActionButton kind="blank" onClick={handleNext}>
										<ArrowRightIcon />
									</ActionButton>
								</div>
							</div>
						</div>
						<div>
							<Swiper
								ref={sliderRef}
								slidesPerView={2}
								spaceBetween={30}
								className="mySwiper">
								{JQ?.lectures?.map((card) => (
									<SwiperSlide style={{ height: 'auto' }} key={card.id + card.type}>
										<ContentRecommendationCard content={card} hasPrice={false} />
									</SwiperSlide>
								))}
							</Swiper>
						</div>
						<div className="w-100 d-flex flex justify-content-end">
							<LinkButton kind="simple" to={`/job-qualifications/${JQ.id}/${JQ.slug}`}>
								Explore all courses in this qualification <ArrowRightIconPink />
							</LinkButton>
						</div>
					</div>
				)}
			</div>
		);
	}

	return (
		<div className={`${classes['jq-tab-view-desktop-wrapper']} d-flex flex-column gap-3`}>
			<h3>{data.headline}</h3>
			<p>{data.description}</p>
			<Box className={`${classes['jq-tab-wrapper']} mt-3`}>
				<Tabs
					orientation="vertical"
					variant="scrollable"
					value={value}
					onChange={handleChange}
					TabIndicatorProps={{
						sx: {
							width: '9px',
							right: 'unset',
							left: '0',
							borderRadius: '8px 0px 0px 8px'
						}
					}}>
					{data.results.map((JQ) => {
						return (
							<Tab
								sx={{
									'&.MuiButtonBase-root': {
										border: 'none',
										color: '#0D0D0D',
										fontSize: '16px',
										fontWeight: '400',
										padding: '24px',
										borderRadius: '8px',
										width: '100%',
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'flex-start',
										textAlign: 'left'
									},
									'&.Mui-selected': {
										borderRadius: '8px 0px 0px 8px',
										backgroundColor: '#FBFAF9',
										color: '#0D0D0D',
										fontSize: '16px',
										fontWeight: '700'
									}
								}}
								label={JQ.title}
								key={JQ.id}
							/>
						);
					})}
					<LinkButton
						kind="simple"
						to={viewAllLink}
						style={{ padding: '24px', alignSelf: 'baseline', fontWeight: '400' }}>
						View All Job Qualifications
					</LinkButton>
				</Tabs>
				<div>
					{data.results.map((JQ, index) => {
						return (
							<TabPanel value={value} index={index} JQ={JQ} key={JQ.id}>
								{JQ.title}
							</TabPanel>
						);
					})}
				</div>
			</Box>
		</div>
	);
};

export default CourseTabViewDesktop;
