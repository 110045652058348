
import React,{useEffect, useState} from 'react';
import useWindowSize from 'hooks/use-windowSize';

import CarouselWrapper from '@core/components/carouselwrapper/CarouselWrapper';

import classes from './Carousel.module.scss';

const Carousel = ({ section }) => {
	const { desktopDevice } = useWindowSize();
	const [start , setStart] = useState('undefined');	
	useEffect(() => {
		setStart(typeof window);
	  }, [start]);
	if (section.results.length === 0) return;
	return (
		<section className={`${classes['carousel-wrapper']} container mt-5`}>
			<CarouselWrapper
				slides={section.results.map((item, index) => (
					<>
						{item.link ? (
							<a key={index} href={item.link} target="_blank" rel="noreferrer">
								<img
									className={`${classes['carousel-image']}`}
									src={
										(start == 'undefined' || desktopDevice) ? item.image : item.mobile_image ? item.mobile_image : item.image
									}
									alt={`slide-${item.title || index}`}
								/>
							</a>
						) : (
							<img
								className={`${classes['carousel-image']}`}
								src={
									(start == 'undefined' || desktopDevice) ? item.image : item.mobile_image ? item.mobile_image : item.image
								}
								alt={`slide-${item.title || index}`}
							/>
						)}
					</>
				))}
			/>
		</section>
	);
};

export default Carousel;

// previous picture creating with text
{
	/* <CarouselWrapper
	slides={section.results.map((item, index) => (
		<>
			{item.link ? (
				<a key={index} href={item.link} target="_blank" rel="noreferrer">
					<div
						key={item.title}
						className={`${classes['carousel']} row d-flex flex-column flex-md-row alig-items-baseline align-items-md-center gap-3 gap-md-0`}>
						<div className="order-2 order-md-1 col-12 col-md-5 d-flex flex-column gap-2 gap-md-3">
							<h3>{item.title}</h3>
							<div dangerouslySetInnerHTML={{ __html: item.description }}></div>
						</div>
						<div className="order-1 order-md-2 col-12 col-md-7">
							<img
								className={`${classes['carousel-image']}`}
								src={item.image}
								alt={`slide-${item.title || index}`}
							/>
						</div>
					</div>
				</a>
			) : (
				<div
					key={item.title}
					className={`${classes['carousel']} row d-flex flex-column flex-md-row alig-items-baseline align-items-md-center gap-3 gap-md-0`}>
					<div className="order-2 order-md-1 col-12 col-md-5 d-flex flex-column gap-2 gap-md-3">
						<h3>{item.title}</h3>
						<div dangerouslySetInnerHTML={{ __html: item.description }}></div>
					</div>
					<div className="order-1 order-md-2 col-12 col-md-7">
						<img
							className={`${classes['carousel-image']}`}
							src={item.image}
							alt={`slide-${item.title || index}`}
						/>
					</div>
				</div>
			)}
		</>
	))}
/>; */
}
