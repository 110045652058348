import { useState } from 'react';

import { useRouter } from 'next/router';

import useWindowSize from 'hooks/use-windowSize';
import UseModal from 'hooks/use-modal';

import { useQuery } from 'react-query';
import { CategoriesService } from 'services/core';

import SearchPageCategories from 'components/common/search/searchpagecategories/SearchPageCategories';
import ActionButton from '@core/components/actionbutton/ActionButton';
import Modal from '@core/components/Modal/Modal';
import ShoppingCart from 'components/common/shoppingcart/ShoppingCart';

import BackButton from '@core/assets/icons/simple-back.svg';

import { MOBILE_FULLSCREEN } from '@core/constants/ModalTypes';

import classes from './Category.module.scss';

const Category = ({ section }) => {
	const [showMore, setShowMore] = useState(false);
	const { data } = useQuery(['categories-home-page'], CategoriesService);
	// const navigate = useNavigate();
	const router = useRouter();
	const { show, showModal, destroyModal } = UseModal();
	const { desktopDevice, tabletDevice } = useWindowSize();

	const showMoreHandler = () => {
		if (desktopDevice) {
			setShowMore(true);
		} else {
			showModal();
		}
	};
	return (
		<>
			<section className={`container`}>
				{tabletDevice && <h1 className={classes['section-title']}>Categories</h1>}
				<div className={`${classes['category-section']} container`}>
					{((showMore && data?.data.results) || section.results).map((item) => (
						<button
							onClick={() => router.push(`/category/${item.id}`)}
							key={item.id}
							className={classes['category-item']}>
							<img src={item.image} alt={item.name} style={{ backgroundColor: item.color + 20 }} />
							<div>{item.name}</div>
						</button>
					))}
					{!showMore && (
						<ActionButton
							onClick={showMoreHandler}
							className={`${classes['view-all-btn']} mt-3`}
							kind="secondary">
							View all categories
						</ActionButton>
					)}
				</div>
			</section>
			<Modal
				type={MOBILE_FULLSCREEN}
				title="All Categories"
				closeIcon={<BackButton />}
				show={show}
				closeHandler={destroyModal}
				actions={<ShoppingCart />}>
				<main className={`${classes['modal-content']}`}>
					<SearchPageCategories categoryHandler={(id) => router.push(`/category/${id}`)} />
				</main>
			</Modal>
		</>
	);
};

export default Category;
