import { useRef, useCallback } from 'react';
import Link from 'next/link';

import useWindowSize from 'hooks/use-windowSize';

import JobBoardJobsCard from 'components/pages/jobboard/jobboardjobs/JobBoardJobsCard/JobBoardJobsCard';
import ActionButton from '@core/components/actionbutton/ActionButton';
import LinkButton from '@core/components/linkbutton/LinkButton';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.scss';

import ArrowRightIcon from 'assets/icons/arrow-right-gray.svg';
import ArrowLeftIcon from 'assets/icons/arrow-left-gray.svg';

import classes from './LatestJobPosts.module.scss';

const LatestJobPosts = ({ section }) => {
	const { mobileDevice, tabletDevice } = useWindowSize();

	const sliderRef = useRef(null);

	const handlePrev = useCallback(() => {
		if (!sliderRef.current) return;
		sliderRef.current.swiper.slidePrev();
	}, []);

	const handleNext = useCallback(() => {
		if (!sliderRef.current) return;
		sliderRef.current.swiper.slideNext();
	}, []);

	return (
		<div className={classes['latest-job-posts-wrapper']}>
			<div className="container d-flex flex-column gap-3 py-5">
				<div
					className={`${classes['latest-job-posts-header']} d-flex flex-column justify-content-between gap-3`}>
					<div className="d-flex flex-row justify-content-between align-items-center">
						<h3>{section.title}</h3>
						<div className="d-flex flex-row">
							<ActionButton kind="blank" onClick={handlePrev}>
								<ArrowLeftIcon />
							</ActionButton>
							<ActionButton kind="blank" onClick={handleNext}>
								<ArrowRightIcon />
							</ActionButton>
						</div>
					</div>
					<p>{section.description}</p>
				</div>
				<div className="my-4">
					<Swiper
						ref={sliderRef}
						slidesPerView={mobileDevice ? 1.1 : tabletDevice ? 2.1 : 3.1}
						spaceBetween={30}
						className="mySwiper">
						{section?.results?.map((job) => (
							<SwiperSlide style={{ height: 'auto' }} className={classes['hover-scale']} key={job.id}>
								<Link
									href={`/job-board?search_keyword=${job.title}`}
									className={classes['latest-job-posts-card-wrapper']}>
									<JobBoardJobsCard jobData={job} hasFavBtn={false} />
								</Link>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
				<div className="d-flex justify-content-center">
					<LinkButton
						kind="primary-color"
						to={'/job-board'}
						style={{ width: '279px', maxWidth: '100%', padding: '14px 16px' }}>
						Explore All Jobs
					</LinkButton>
				</div>
			</div>
		</div>
	);
};

export default LatestJobPosts;
