import { sendRequest } from './index';
import { API_RECRUITMENT_URL } from '@core/constants/API';

export const jobBoardMetaData = {
	GET: () =>
		sendRequest({
			url: `${API_RECRUITMENT_URL}job-posts/metadata`,
			method: 'GET'
		})
};

export const jobBoardJobs = {
	GET: (filters) => {
		const queryParams = Object.keys(filters).reduce((searchparams, currentKey) => {
			if (filters[currentKey] !== '') {
				if (searchparams.length === 0) {
					return `?${currentKey}=${filters[currentKey]}`;
				} else {
					return searchparams + `&${currentKey}=${filters[currentKey]}`;
				}
			} else {
				return searchparams;
			}
		}, '');
		return sendRequest({
			url: `${API_RECRUITMENT_URL}job-posts${queryParams}`,
			method: 'GET'
		});
	}
};

export const jobBoardJob = {
	GET: (id) => {
		return sendRequest({
			url: `${API_RECRUITMENT_URL}job-posts/${id}`,
			method: 'GET'
		});
	}
};

export const jobBoardJobApply = {
	GET: (id) => {
		return sendRequest({
			url: `${API_RECRUITMENT_URL}job-posts/${id}/apply`,
			method: 'GET'
		});
	},
	POST: ({ activeJob, payload }) => {
		return sendRequest({
			url: `${API_RECRUITMENT_URL}job-posts/${activeJob}/apply`,
			method: 'POST',
			body: payload
		});
	},
};

export const jobBoardFav = {
	POST: (id) => {
		return sendRequest({
			url: `${API_RECRUITMENT_URL}job-posts/${id}/favorites`,
			method: 'POST'
		});
	},
	DELETE: (id) => {
		return sendRequest({
			url: `${API_RECRUITMENT_URL}job-posts/${id}/favorites`,
			method: 'DELETE'
		});
	},
};

