import React from 'react';
import stuttgartUniversity from 'assets/images/university-of-stuttgart-logo.png';
import jacobuniversity from 'assets/images/Jacobs_University_Bremen_logo 1.png';
import mygermanyuniversity from 'assets/images/My_German_university.png';
import BinginUniversity from 'assets/images/uni-logo-bingen.svg?url';
import BremenUniversity from 'assets/images/uni-logo-bremen.svg?url';
import CauUniversity from 'assets/images/uni-logo-cau.svg?url';



import classes from './UniversityPartners.module.scss';

const UniversityPartners = () => {
	return (
		<div className={`${classes['university-partenrs-wrapper']}`}>
			<div className="container d-flex flex-column flex-md-row flex-wrap justify-content-between align-items-center mw-50">			
				<img src={BinginUniversity} className='py-4 py-md-0' alt="Bingen-University" />
				<img src={BremenUniversity} className='py-5 py-md-0' alt="University-Bremen" />
				<img src={CauUniversity} className='py-4 py-md-0' alt="CAU-University" />
			</div>
		</div>
	);
};

export default UniversityPartners;
