import useWindowSize from 'hooks/use-windowSize';
import ScrollAnimation from 'react-animate-on-scroll';

import LinkButton from '@core/components/linkbutton/LinkButton';

import PartnerPharmuniImg from 'assets/images/join-pharmuni-partner-with-us-new.png';
import PharmuniBussinesLogo from '@core/assets/logo/pharmuni-business-big.svg';

import classes from './JoinPharmuni.module.scss';

const JoinPharmuni = () => {
	const { tabletDevice } = useWindowSize();
	return (
		<div className={`${classes['join-pharmuni-wrapper']} py-5 my-2 my-lg-5`}>
			<div className="container">
				<div
					className={`${classes['join-pharmuni-cart']} ${classes['join-pharmuni-cart__partner']} row gap-5 gap-xl-4`}>
					<ScrollAnimation className="col-12 col-lg-5" animateIn="fadeInLeft">
						<img src={PartnerPharmuniImg.src} alt="join-pharmuni"  />
					</ScrollAnimation>
					<ScrollAnimation  animateIn="fadeInRight" className="d-flex flex-column justify-content-center  gap-3 gap-md-4 col-12 col-lg-6 p-xl-3 p-0">
						<PharmuniBussinesLogo />
						<h2>Enhance Your Recruitment with Pharmuni</h2>
						<ul className="d-flex flex-column gap-2">
							<li>
								<div>
									<span className={classes['dot']} />
								</div>
								<span>Access top pharmaceutical talent with the right skills</span>
							</li>
							<li>
								<div>
									<span className={classes['dot']} />
								</div>
								<span>Streamline your recruitment process efficiently</span>
							</li>
							<li>
								<div>
									<span className={classes['dot']} />
								</div>
								<span>Build a strong talent pipeline for sustained growth</span>
							</li>
							<li>
								<div>
									<span className={classes['dot']} />
								</div>
								<span>Effortlessly reach qualified candidates at lower costs</span>
							</li>
						</ul>
						<LinkButton to="/career-hub" kind="primary-color" className="">
							Partner With Us
						</LinkButton>
					</ScrollAnimation>
				</div>
			</div>
		</div>
	);
};

export default JoinPharmuni;
