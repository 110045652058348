import React from 'react';

import LinkButton from '@core/components/linkbutton/LinkButton';

import applicationFrames from 'assets/images/applications-frames.svg?url';
import applicationsBackground from 'assets/images/application-background.svg?url';
import ApplicationsAppstore from 'assets/images/applications-appstore.svg';
import ApplicationsGooglestore from 'assets/images/applications-google-play.svg';
import ArrowRightIcon from 'assets/icons/arrow-right.svg';

import classes from './Applications.module.scss';

const Applications = () => {
	let origin = '';
	if (typeof window !== 'undefined') {
		origin = window.location.origin;
	}
	return (
		<section
			className={`${classes['applications-wrapper']} d-flex flex-row `}
			style={{ background: `url(${applicationsBackground})` }}>
			<article className="d-flex flex-row align-items-center justify-content-around gap-5 container">
				<div className="d-none d-xl-block col-4">
					<img src={applicationFrames} alt="applications" className="w-100" />
				</div>
				<div className="d-flex flex-column align-items-center  justify-content-center align-items-xl-start col-12 col-xl-6">
					<h2>Get the Pharmuni Application</h2>
					<img
						src={applicationFrames}
						alt="applications"
						className="w-100 d-block d-xl-none col-6"
					/>
					<div className="d-flex flex-row align-items-center">
						<a
							href="https://play.google.com/store/apps/details?id=com.zamann.pharmuni"
							target="blank"
							referrerPolicy="no-referrer">
							<ApplicationsGooglestore />
						</a>
						<a
							href="https://apps.apple.com/app/pharmuni/id1586543010"
							target="blank"
							referrerPolicy="no-referrer">
							<ApplicationsAppstore />
						</a>
					</div>
					<LinkButton
						kind="simple"
						href={`${origin}/career-hub/`}
						className="d-flex flex-row align-items-center gap-1"
						rel="noreferrer">
						Downolad the mobile app here <ArrowRightIcon />
					</LinkButton>
				</div>
			</article>
		</section>
	);
};

export default Applications;
