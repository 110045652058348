import React, { useState, useEffect } from 'react';

import LinkButton from '@core/components/linkbutton/LinkButton';

import BlackFridayImage from '@core/assets/images/blackfriday-main.svg?url';

import classes from './BlackFriday.module.scss';


const FlipCard = ({ value }) => {
    const [currentValue, setCurrentValue] = useState(value);
    const [flipping, setFlipping] = useState(false);

    useEffect(() => {
        if (value !== currentValue) {
            setFlipping(true);
            // setCurrentValue(value);           
            setTimeout(() => {
                setFlipping(false);
            }, 500); // Match animation duration
            setTimeout(() => {
                setCurrentValue(value);
            }, 250); // change value
        }
    }, [value, currentValue]);

    return (
        <div className={classes["flip-card"]}>
            <div className={classes.top}>
                <span>
                    {currentValue[0]}
                </span>
                <span>
                    {currentValue[1]}
                </span>
            </div>
            <div className={classes.bottom}>
                <span>
                    {currentValue[0]}
                </span>
                <span>
                    {currentValue[1]}
                </span>
            </div>
            {flipping && (
                <>
                    <div className={classes["top-flip"]}>
                        <span>
                            {currentValue[0]}
                        </span>
                        <span>
                            {currentValue[1]}
                        </span>
                    </div>
                    <div className={classes["bottom-flip"]}>
                        <span>
                            {value[0]}
                        </span>
                        <span>
                            {value[1]}
                        </span>
                    </div>
                </>
            )}
        </div>
    );
};

const TimerSegment = ({ title, tens, ones }) => (
    <div className={classes["container-segment"]}>
        <div className={classes.segment}>
            {/* <FlipCard value={tens} /> */}
            <FlipCard value={ones} />
        </div>
        <div className={classes["segment-title"]}>{title}</div>
    </div>
);

const Timer = ({ time }) => {
    const [timeLeft, setTimeLeft] = useState(Math.floor(time / 1000)); // 24 hours in seconds

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeLeft((prev) => Math.max(prev - 1, 0));
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const days = Math.floor(timeLeft / (3600 * 24));
    const hours = Math.floor(timeLeft / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);
    const seconds = timeLeft % 60;

    return (
        <div className='d-flex gap-3'>
            <TimerSegment
                title="Days"
                tens={Math.floor(days / 10)}
                ones={('0' + days).slice(-2)}
            />
            <TimerSegment
                title="Hours"
                tens={Math.floor(hours / 24)}
                ones={('0' + hours % 24).slice(-2)}
            />
            <TimerSegment
                title="Minutes"
                tens={Math.floor(minutes)}
                ones={('0' + minutes).slice(-2)}
            />
            <TimerSegment
                title="Seconds"
                tens={Math.floor(seconds)}
                ones={('0' + seconds).slice(-2)}
            />
        </div>
    );
};

const BlackFriday = ({ data }) => {
    const year = new Date().getFullYear();
    const difference = +new Date(data.valid_until) - +new Date();
    return (
        <div className={classes['wrapper']}>
            <div className='container'>
                <div className='d-flex flex-column align-items-center gap-4'>
                    <h3>
                        Pharmuni Biggest Sale of the Year!
                    </h3>
                    <img src={BlackFridayImage} alt='Black Friday Image' />
                    {/* <h1>
                        Get ready<br /> for our <span>Biggest Sale</span> of the year!
                    </h1> */}

                    <p>
                        Access all premium pharma courses and career tools for<strong> half the price</strong>. Offer ends December 2nd.
                    </p>

                    <Timer time={difference} />

                    <LinkButton kind='primary-color' to={'/plans'}>
                        Subscribe Today!
                    </LinkButton>
                    {/* {data.valid_until} */}
                </div>
            </div>
        </div>
    );
};

export default BlackFriday;


