import { useRef, useCallback } from 'react';

import useWindowSize from 'hooks/use-windowSize';

import ActionButton from '@core/components/actionbutton/ActionButton';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.scss';

import { Star1 } from 'iconsax-react';

import ArrowRightIcon from 'assets/icons/arrow-right-gray.svg';
import ArrowLeftIcon from 'assets/icons/arrow-left-gray.svg';
import StarRating from 'assets/icons/star-rating.svg';

import classes from './GoogleReviews.module.scss';

const GoogleReviews = ({ section }) => {
	const { mobileDevice, tabletDevice } = useWindowSize();

	const sliderRef = useRef(null);

	const handlePrev = useCallback(() => {
		if (!sliderRef.current) return;
		sliderRef.current.swiper.slidePrev();
	}, []);

	const handleNext = useCallback(() => {
		if (!sliderRef.current) return;
		sliderRef.current.swiper.slideNext();
	}, []);

	return (
		<div className={classes['google-reviews-wrapper']}>
			<div className="container d-flex flex-column gap-3 py-5 my-5">
				<div
					className={`${classes['google-reviews-header']} d-flex flex-column justify-content-between gap-3`}>
					<div className="d-flex flex-row justify-content-between align-items-center">
						<h3>
							Hear from Our Thriving Community in <span>Pharmuni</span>
						</h3>
						<div className="d-flex flex-row">
							<ActionButton kind="blank" onClick={handlePrev}>
								<ArrowLeftIcon />
							</ActionButton>
							<ActionButton kind="blank" onClick={handleNext}>
								<ArrowRightIcon />
							</ActionButton>
						</div>
					</div>
				</div>
				<div className="my-4">
					<Swiper
						ref={sliderRef}
						slidesPerView={mobileDevice ? 1.1 : 2.1}
						spaceBetween={30}
						className="mySwiper">
						{section?.results?.map((review) => (
							<SwiperSlide style={{ height: 'auto', marginBottom: '5px' }} className='p-3' key={review.comment}>
								<div
									className={`${classes['google-reviews-card-wrapper']}  d-flex flex-column gap-4`}>
									<div className="d-flex flex-row gap-3 ">
										<img src={review.image} alt={review.name} />
										<div className="d-flex flex-column flex-xl-row align-items-xl-center gap-2 gap-xl-1 flex-grow-1">
											<h4 className='order-0'>{review.name}</h4>
											<span className="d-none d-xl-inline"> - </span>
											<p className='order-1 order-xl-1'>{review.date}</p>
											<div className='d-flex flex-row gap-1 flex-grow-1 justify-content-xl-end order-1 order-xl-2'>
												{Array(5)
													.fill(1)
													.map((_val, index) => (
														<Star1
                              key={index}
															color="#D9D9D9"
                              size={20}
															className={`${
																review.rate >= index + 1
																	? classes['filled-star']
																	: classes['empty-star']
															}`}
														/>
													))}
											</div>
										</div>
									</div>
									<p>{review.comment}</p>
								</div>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
		</div>
	);
};

export default GoogleReviews;
