import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';

import UseToast from 'hooks/use-toast';

import TextInput from '@core/components/textinput/TextInput';

import { RequestNewsletter } from 'services/newsletter';

import classes from './Form.module.scss';

const Form = () => {
	const { toast, ToastContainer } = UseToast();
	const { mutate } = useMutation(['request-newsletter'], RequestNewsletter, {
		onSuccess: () => {
			toast(
				'We’ve sent a verification Email. Please follow the instruction in the email',
				'success'
			);
		},
		onError: (err) => {
			toast(
				err.response.data.data.email?.[0] || err.response.data.data.non_field_errors?.[0],
				'alert'
			);
		}
	});
	const {
		register,
		handleSubmit,
		formState: { errors, isDirty, isValid }
	} = useForm({ mode: 'onChange' });

	const submitForm = (payload) => {
		mutate(payload);
	};

	return (
		<>
			<form onSubmit={handleSubmit(submitForm)} className={classes['subscribe-form']}>
				<TextInput
					input={{
						label: 'Name',
						name: 'first_name',
						id: 'first_name'
					}}
					errors={errors}
					register={register}
				/>
				<TextInput
					input={{
						label: 'Last Name',
						name: 'last_name',
						id: 'last_name'
					}}
					errors={errors}
					register={register}
				/>
				<TextInput
					validation={{
						required: true
					}}
					input={{
						label: 'Email (required)',
						name: 'email',
						id: 'email'
					}}
					errors={errors}
					register={register}
				/>
				<button type="submit" disabled={!isDirty || !isValid}>
					Subscribe <span>to newsletter</span>
				</button>
				<p>
					By clicking on “Subscribe”, you agree to our
					<a href={`${window?.location.origin}/privacy-policy/`} rel="noreferrer">
						Privacy Policy
					</a>
				</p>
			</form>
			<ToastContainer />
		</>
	);
};

export default Form;
