import CarouselWrapper from '@core/components/carouselwrapper/CarouselWrapper';

import PartnerImage1 from 'assets/images/partner-cau-norm.svg';
import PartnerImage2 from 'assets/images/partner-hsb.svg';
import PartnerImage3 from 'assets/images/partner-th-bingen.svg';

import PartnerLogo1 from 'assets/images/partner-cau-log.svg';

import classes from './OurPartners.module.scss';

const OurPartners = ({ section }) => {
	return (
		<div className={`${classes['our-partners-wrapper']}`}>
			<div className="container d-flex flex-column gap-5 align-items-center">
				<h2 className="w-100">{section.title}</h2>
				<div className={`${classes['our-partners-carousel-wrapper']}`}>
					<CarouselWrapper
						delay={8000}
						slides={[
							<div
								className={`d-flex flex-column align-items-center justify-content-center ${classes['partner-card']}`}>
								<div>
									<PartnerLogo1 />
								</div>
								<p className="my-2 my-md-4">
									‘’Pharmuni approached our biochemistry student body with an exciting opportunity
									for e-learning to acquire certified skills. As the request for the engagement was
									from our student council, I was more than happy to facilitate it.‘’ 
								</p>
								<h5>Testimonial by</h5>
								<span>Christian-Albrechts-University of Kiel </span>
							</div>,
							<div
								className={`d-flex flex-column align-items-center justify-content-center ${classes['partner-card']}`}>
								<div>
									<PartnerImage2 />
								</div>
								<p className="my-2 my-md-4">
									‘’Pharmuni supports our students with additional up to date knowledge from the
									professional life science industry. We called Pharmuni for an interview after
									receiving a very informative and interesting flyer, and we immediately noticed
									that our University of Applied Sciences approach for digital innovation in studies
									and teaching and Pharmuni share overlapping interests. ‘’ 
								</p>
								<h5>Testimonial by</h5>
								<span>Applies Sciences University Bremen</span>
							</div>
						]}
					/>
				</div>
				<div
					className={`d-flex flex-column flex-md-row gap-4 gap-md-0 justify-content-md-between align-items-center ${classes['partners-icon']}`}>
					<PartnerImage1 />
					<PartnerImage2 />
					<PartnerImage3 />
				</div>
			</div>
		</div>
	);
};

export default OurPartners;
