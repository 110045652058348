import Link from 'next/link';

import Badge from '@core/components/badge/Badge';

import UseSlugRoute from 'hooks/use-slugroute';

import { Updated } from 'constants/content-status';

import StarIcon from 'assets/icons/star-rating.svg';

import classes from './CourseCard.module.scss';

const CourseCard = ({ card }) => {
	const slugRoute = UseSlugRoute();

	return (
		<Link
			href={slugRoute(`/job-qualifications/${card.id}`, card.slug)}
			className={classes['course-container']}>
			<img src={card.image} alt="" />
			<div className={classes['course-bottom']}>
				<div className={`${classes['course-status']} d-flex flex-row gap-1 align-items-center`}>
					{card.has_been_updated && <Badge status={Updated} />}
					<Badge status={card.status} />
				</div>
				<p>{card.title}</p>
				<div className={classes['course-info']}>
					<div className={classes['rating']}>
						<StarIcon /> {card.rate_average}
					</div>
					<span className={classes['dot']} />
					<div className={classes['stuff']}>{card.participants_count} participants</div>
					<span className={classes['dot']} />
					<div className={classes['stuff']}>{card.lecture_count} courses</div>
				</div>
			</div>
		</Link>
	);
};

export default CourseCard;
