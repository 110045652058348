import { useRef, useState } from 'react';

import ScrollAnimation from 'react-animate-on-scroll';

import ContentRecommendationCard from 'components/common/contentrecommendationcard/ContentRecommendationCard';

import { SwiperSlide } from 'swiper/react';
// import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';

import classes from './LecturesWithCategory.module.scss';
import CartCarouselSwiperWithCategory from './CartCarouselSwiperWithCategory/CartCarouselSwiperWithCategory';

const LectureWithCategory = ({ section, origin }) => {
	if (section.results.length === 0) return;
	const [selectedCategory, setSelectedCategory] = useState(section.results[0].name);
	return (
		<div
			style={{ position: 'relative' }}
			className="py-5">
			<div className={`${classes['section-wrapper']} container`}>
				<div className='row'>
					<ScrollAnimation animateIn="fadeInLeft" className='col-xl-6 col-12'>
						<h2>
							Meeting all your pharma training needs
						</h2>
						<h3>
							All our courses are crafted by leading experts in the pharmaceutical industry and
							are carefully designed to provide the most effective and practical learning experience.
						</h3>
						<div className={`${classes['category-wrapper']} d-flex gap-3 mb-5  justify-content-start`}>
							{section.results.map((category) => (
								<div className={`${classes['category']} ${(category.name === selectedCategory ? classes['active'] : '')}`} onClick={() => setSelectedCategory(category.name)}>
									{category.name}
								</div>
							))}
						</div>
						<p>
							{section.results.filter((category) => category.name === selectedCategory).map((category) => (<div>{category.description}</div>))}
						</p>
					</ScrollAnimation>
					<div className={` col-xl-6 col-12 mt-4 mt-xl-0`}>
						<ScrollAnimation animateIn="fadeInRight">
							<CartCarouselSwiperWithCategory section={section} origin={origin}>
								{section.results.filter((category) => category.name === selectedCategory).map((category) => (
									<div >
										{category.lectures.map((lecture) => (
											
												<SwiperSlide style={{ height: '370px' }} className={classes['hover-scale']} >
													<ContentRecommendationCard content={lecture} className="home-page" />
												</SwiperSlide>
											
										))}
									</div>
								))}
							</CartCarouselSwiperWithCategory>
						</ScrollAnimation>
					</div>
				</div>

			</div>
		</div>
	);
};

export default LectureWithCategory;