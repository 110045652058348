import { useRef } from 'react';

import CartCarousel from '@core/components/cartcarousel/CartCarousel';
import CartCarouselSwiper from '@core/components/CartCarouselSwiper/CartCarouselSwiper';
import LectureCard from 'components/common/lecturecard/LectureCard';

import ContentRecommendationCard from 'components/common/contentrecommendationcard/ContentRecommendationCard';

import { SwiperSlide } from 'swiper/react';
// import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';

import classes from './Lecture.module.scss';

const Lecture = ({ section, origin }) => {
	if (section.results.length === 0) return;
	const id = section.show_more_id === 'free-lectures' ? { id: 'free-courses' } : {};
	return (
		<div
			style={{ position: 'relative' }}
			className="py-5">
			<span {...id} style={{ position: 'absolute', top: '-90px', visibility: 'hidden' }}></span>
			<div
				className={
					section.show_more_id === 'free-lectures'
						? `${classes['free-lectures-wrapper']} container`
						: `${classes['free-lectures-wrapper']} container`
				}
				// style={{backgroundColor: section.background_color || 'transparent'}}				
				>
				<CartCarouselSwiper section={section} origin={origin}>
					{section.results.map((content) => (
						<SwiperSlide style={{ height: 'auto' }} className={classes['hover-scale']} key={content.id + content.type}>
							<ContentRecommendationCard content={content} className="home-page" key={content.id} />
						</SwiperSlide>
					))}
				</CartCarouselSwiper>
			</div>
		</div>
	);
};

export default Lecture;
